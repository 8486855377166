<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAddSelect"
                    >
                        新增商品
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="100"
                />
                <el-table-column
                    label="商品封面"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.coverImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    label="商品价格"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.priceType == 1">{{ scope.row.goodsPrice.price1 }}</span>
                        <span v-if="scope.row.priceType == 2">{{ scope.row.goodsPrice.price1 }}-{{ scope.row.goodsPrice.price2 }}</span>
                        <span v-if="scope.row.priceType == 3">原价:{{ scope.row.goodsPrice.price1 }}<br></span>
                        <span v-if="scope.row.priceType == 3">现价:{{ scope.row.goodsPrice.price2 }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="100"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <el-dialog
            title="选择商品"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="goodsId"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="goodsName"
                        label="商品名称"
                        min-width="100"
                    />
                    <el-table-column
                        label="商品封面"
                        width="120"
                    >
                        <template slot-scope="scope">
                            <ImageList
                                :data="scope.row.coverImageUrl"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="商品价格"
                        min-width="100"
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.priceType == 1">{{ scope.row.goodsPrice.price1 }}</span>
                            <span v-if="scope.row.priceType == 2">{{ scope.row.goodsPrice.price1 }}-{{ scope.row.goodsPrice.price2 }}</span>
                            <span v-if="scope.row.priceType == 3">原价:{{ scope.row.goodsPrice.price1 }}<br></span>
                            <span v-if="scope.row.priceType == 3">现价:{{ scope.row.goodsPrice.price2 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="remarks"
                        label="备注"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'LiveRoomGoods',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                liveRoomId: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsName: '', // 商品名称
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Pm.LiveGoods.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectDialogTable) {
                    this.$refs.selectDialogTable.clearSelection();
                }
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Pm.LiveGoods.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
                goodsStatus: 100,
                liveRoomIdNe: this.queryFormModel.liveRoomId,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            // 插入新数据
            const list = [];
            this.selectDialog.currentTableSelect.forEach(item => {
                list.push(item.id);
            });
            this.$api.Pm.LiveRoom.addLiveGoods({
                liveRoomId: this.queryFormModel.liveRoomId,
                liveGoodsIds: list.join(','),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onQuery();
                this.selectDialog.isVisible = false;
            });
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
    },
    created() {
        this.queryFormModel.liveRoomId = this.$route.params.id;
    },
};
</script>

<style lang="scss">
</style>
